import React from "react"
import { graphql } from "gatsby"
import { MDXRenderer } from "gatsby-plugin-mdx"
import { css } from "styled-components"

import Layout from "../layouts/layout"
import { UiText, Heading } from "../components"
import SEO from "../components/seo"
import "katex/dist/katex.min.css"
const { format } = require("date-fns")

export const query = graphql`
  query ($slug: String!) {
    mdx(frontmatter: { slug: { eq: $slug } }) {
      frontmatter {
        title
        author
        date
      }
      body
    }
  }
`

const PostTemplate = ({ data: { mdx: post } }) => (
  //
  <>
    <SEO title={post.frontmatter.title} />
    <Layout>
      <div
        css={css`
          position: relative;
          max-width: 960px;
          margin: auto;
          padding: 40px 0px;
        `}
      >
        <Heading level={1} variant="h1" as="h1" style={{ textAlign: "center" }}>
          {post.frontmatter.title}
        </Heading>
        <UiText variant="subContent" as="p" style={{ textAlign: "center" }}>
          Post by <b>{post.frontmatter.author}</b> on{" "}
          <b> {format(new Date(post.frontmatter.date), "MMM dd, yyyy")}</b>
        </UiText>
        <UiText style={{ textAlign: "center" }}>
          <a
            className="twitter-share-button"
            href="https://twitter.com/intent/tweet"
            data-hashtags="Souleymane Dembele, Tech, Hardware, Software"
            data-via="souleymane"
            data-related="hardware software engineering"
          />
        </UiText>
        <MDXRenderer>{post.body}</MDXRenderer>
      </div>
    </Layout>
  </>
)

export default PostTemplate
